//import '../App.css';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import MarkdownToJsx from 'markdown-to-jsx';
import {Page} from "../../Components/Page"
import {BlogPost} from "./util"

function AuthoringToolsTutorialPost(){
  return(
      <BlogPost title="Getting Started with the CodeSpells Authoring Tools" date={"???"}>
        <MarkdownToJsx>
        {`
Coming soon.  This blog post (when we write it), will ensure that the \`codespells-react\` authoring tools have been completely ported over. 

We'll make a simple survival game demo with:

* An inventory UI (built with \`codespells-react\`)
* Some simple resources that spawn in the world (using \`codespells-unreal-plugins\`)

          `}
        </MarkdownToJsx>
      </BlogPost>
  )
}

export default AuthoringToolsTutorialPost
