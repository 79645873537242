//import '../App.css';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import MarkdownToJsx from 'markdown-to-jsx';
import {Page} from "../../Components/Page"
import {BlogPost} from "./util"
import {BlogPostLink} from "./index" //circular dep, is this okay, Mr. JavaScript?
import AuthoringToolsHelloWorldPost from "./AuthoringToolsHelloWorldPost" 
import AuthoringToolsTutorialPost from "./AuthoringToolsTutorialPost" 

function BasicReactLibraryCompletePost(){

  return(
      <BlogPost title="Basic React Library Complete" date={"Feb 18, 2022"}>
        <MarkdownToJsx>
        {`Today, we worked more on an upcoming blog post (one that will help developers get started using the CodeSpells Authoring Tools for their own authored works).  You can read the draft here:  
          `}
        </MarkdownToJsx>
        <BlogPostLink to={AuthoringToolsHelloWorldPost} />
        <br/>
        <MarkdownToJsx>
        {`The React part of the tutorial can now be followed.  The Racket and Unreal parts are still in progress.
          
Note that it turned out to be SURPRISINGLY difficult to put together a React component library that had everything we wanted in it (typescript, rollup, and webpack dev server), so when we finally got everything configured, we made a starter repo so we wouldn't have to do it again.  Feel free to [use it](https://github.com/thoughtstem/typescript-react-component-library-starter) if it sounds useful to you.

Also, we're itching to do a more in-depth tutorial after the upcoming Hello World tutorial, so we started a draft blog post for that:
          `}
        </MarkdownToJsx>
        <BlogPostLink to={AuthoringToolsTutorialPost} />
        <br/>
        <MarkdownToJsx>
        {`
We like survival games :)`}
        </MarkdownToJsx>
      </BlogPost>
  )
}

export default BasicReactLibraryCompletePost
